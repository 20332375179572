export default {
  HOME: '/',
  BECOME_MODEL: '/become-a-model',
  CONTACT: '/contact',
  MODELS: '/models',
  MODELS_NEW: '/models/new',
  MODELS_MAIN: '/models/main',
  MODELS_DEVELOPMENT: '/models/development',
  NEWS: '/news',
  PRODUCTION: 'https://www.onmovecreative.com/',
};
